import Scrollbars from "react-custom-scrollbars";
import FlyGoImage from "../../images/flygo.png";
import GuideImage from "../../images/guide.png";
import styles from "./style.module.scss";
const Home = () => {
  return (
    <div className={styles.container}>
      <Scrollbars>
        <div className={styles.content}>
          <h1>❓How to earn with our signals?</h1>
          {/* <p className={styles.date}>27 марта 2024 от January 03, 2024 • June 04, 2024</p> */}
          <p>
            It's very simple, friends ! Our team with professional tools and lots of experience will
            tell you the time for you when to put in.
          </p>
          <p>1) Before starting signals register on this site and top up your balance </p>
          <p>2) Find the FLYGO game on this site</p>
          <img src={FlyGoImage} alt="flygo" />
          <p>
            3) When you see this message on my telegram-channel quickly go to the site and make
            bet❗️
          </p>
          <img src={GuideImage} alt="guide" />
          <p className={styles.notes}>
            ( we recommend betting on 2 odds at once X1.5 and X2 this reduces the probability of
            losing even more )
          </p>
          <p>
            4) Congratulations 🏆 In 95% our signals are correct and you will be able to take your
            winnings !
          </p>
          <p className={styles.underlined}>Start making your dreams come true right now !</p>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Home;
