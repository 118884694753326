import { useEffect } from "react";
import "./App.css";
import { Background } from "./components";
import Home from "./pages/home";

function App() {
  const initStyle = () => {
    let _vh = window.innerHeight * 0.01;
    window.document.documentElement.style.setProperty("--vh", `${_vh}px`);
    window.document.documentElement.style.setProperty("background", `#f5f5f5`);
  };

  const defaultTheme = require(`./theme/defaultTheme.json`);
  const finalTheme = { ...defaultTheme };
  const setTheme = () => {
    const r = document.querySelector(":root");
    const final = Object.entries(finalTheme);
    final.map((color) => {
      r.style.setProperty(color[0], color[1]);
    });
  };

  useEffect(() => {
    setTheme();
  }, [finalTheme]);
  useEffect(() => {
    initStyle();
  }, []);
  return (
    <Background>
      <div className="App">
        <Home />
      </div>
    </Background>
  );
}

export default App;
