import "./style.scss";
const Background = ({ navType, children }) => {
  return (
    <div
      style={{
        background: "#12171E",
        width: "100vw",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
      {children}
    </div>
  );
};
export default Background;
